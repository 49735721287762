import React from "react";

import { Text, Box } from "flicket-ui";

import { Card, SlateRenderer } from "~components";
import { getImageSrc, i18n } from "~lib";
import { ExtendedFile, SeasonsQuery } from "~graphql/sdk";
import { remainingEventsForSeason } from "~lib/helpers/remainingEventsForSeason";
import { useOrganization } from "~hooks";
import { ZonedDate, FormatDateOptions } from "@flicket/utils";
import { formatEventDateRange } from "~lib/helpers/formatDate";

type DateOrString = string | Date;

type TourOverviewTileProps = {
  id: string;
  name: string;
  events: SeasonsQuery["seasons"][number]["events"];
  startDate: string;
  endDate: string;
  thumbnail: ExtendedFile;
  description: string;
};

export const TourOverviewTile = ({
  id,
  name,
  events,
  startDate,
  endDate,
  thumbnail,
  description,
}: TourOverviewTileProps) => {
  const { hasFeature } = useOrganization();

  const eventDate = formatEventDateRange(
    startDate,
    // If a single event, force the formatter to output a single
    // date because start and end are on the same day.
    events.length > 1 ? endDate : startDate,
    {
      format: "basicShort",
      showStartTime: !hasFeature("hideEventTime"),
      timeZone: i18n.timezone,
      locale: i18n.locale,
    }
  );

  const remainingEventsCount = remainingEventsForSeason(events).length;

  return (
    <Box p="6/4" width={{ _: "100%", sm: "50%", md: "33.33%" }} key={id}>
      <Card
        minHeight="100%"
        linkProps={{
          to: `/tours/[tourId]`,
          linkAs: `/tours/${id}`,
        }}
        image={getImageSrc(thumbnail)}
      >
        <Text
          fontSize={4}
          lineHeight="medium"
          fontWeight="heavy"
          variant="uppercase"
          letterSpacing={"-0.17px" as any}
          mb={1}
        >
          {`${name}`}
        </Text>

        <Text
          mb={1}
          fontSize={4}
          lineHeight="medium"
          letterSpacing={"-0.17px" as any}
        >
          {remainingEventsCount}
          {remainingEventsCount > 1 ? " events" : " event"}
        </Text>

        <Text fontSize={3} lineHeight="medium" letterSpacing={"-0.17px" as any}>
          {eventDate}
        </Text>

        {description && (
          <Box>
            <SlateRenderer
              value={description}
              mt={1}
              // Min height required for Safari as the below code
              // does not work for some reason. caniuse.com is showing all
              // properties available 🤷‍♂️
              minHeight={70}
              css={{
                "-webkit-line-clamp": "3",
                display: "-webkit-box",
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
              }}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
};
